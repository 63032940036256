@import '../../styles/ui/Size.module.scss';
@import '../../styles/ui/UI.module.scss';

.input {
  @apply tw-rounded-md tw-w-full tw-outline-none tw-box-border;
  background-color: transparent;

  @include queue-ui-x-padding;
  @include queue-ui-fixed-height;

  @include queue-ui-size-medium {
    @apply tw-h-[28px] tw-px-2 tw-py-1.5 tw-text-xs tw-leading-none tw-font-normal;
  }

  &:disabled {
    background-color: var(--gray-2);
    color: var(--gray-8);
    cursor: not-allowed;
  }

  &::--webkit-inner-spin-button,
  &::--webkit-outer-spin-button {
    @apply tw-appearance-none tw-hidden tw-m-0;
  }
}

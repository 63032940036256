.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.Content {
  @apply tw-flex tw-flex-1 tw-min-h-0;
}

.Left {
  width: 200px;
}

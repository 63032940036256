.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}

[data-highlighted] > .RightSlot {
  color: white;
}

[data-disabled] .RightSlot {
  color: var(--mauve-8);
}

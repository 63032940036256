.container {
  border-right: 1px solid var(--gray-5);
  height: 100%;
}

.inputContainer {
  border-bottom: 1px solid var(--gray-5);
}

.input {
  height: 36px;
  margin: 0 8px;
}

.canvas {
  margin: 10px;
  width: 30px;
  height: 30px;
}

.objectGroupTitle {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: #eee;
  }

  &:active {
    background: #ddd;
  }
}

.objectGroupArrow {
  margin-left: 8px;
  margin-right: 8px;
}

.objectList {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.object {
  width: 50px;
  height: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  &:hover {
    background: #eee;
  }

  &:active {
    background: #ddd;
  }
}




.ScrollAreaRoot {
  @apply tw-flex tw-flex-1;
}

.ScrollAreaViewport {
  @apply tw-flex tw-flex-1;
}

.TooltipContent {
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
  hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.TooltipContent[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.TooltipArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  background-color: white;
  box-shadow: 0 2px 10px var(--black-a7);
}
.IconButton:hover {
  background-color: var(--violet-3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

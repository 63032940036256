


@import '../../styles/popover/Popover.module.scss';
@import '../../styles/list/List.module.scss';
@import '../../components/buttons/button/Button.module.scss';

@mixin queue-select-trigger-color-default {
  &.default {
    background-color: #e7e6eb;
    color: var(--gray-12);

    &[data-disabled] {
      background-color: var(--gray-2);
      color: var(--gray-8);
    }
  }
}

.SelectTrigger {
  @apply tw-flex tw-items-center tw-justify-between tw-rounded-md tw-outline-none tw-cursor-pointer tw-box-border tw-w-full tw-font-normal;

  // sizing
  @include queue-ui-x-padding;
  @include queue-ui-fixed-height;

  @include queue-ui-size-medium {
    @apply tw-px-2 tw-py-1.5 tw-text-xs tw-leading-none tw-font-normal;
    height: 28px;
  }

  // theme
  @include queue-select-trigger-color-default;

  &[data-placeholder] {
    color: var(--gray-8);
  }

  &[data-disabled] {
    @apply tw-cursor-not-allowed;
  }

  span {
    @apply tw-font-normal;
  }
}

.SelectContent {
  @include popover;
}

.SelectGroupLabel {
  @include list-group-label;
}

.SelectItem {
  @include list-item;
}

.SelectItem[data-disabled] {
  @include list-item-disabled;
}

.SelectItem[data-highlighted] {
  @include list-item-hover;
}

.SelectItemIndicator {
  @include list-item-indicator;
}

.SelectSeparator {
  @include list-separator;
}

.SelectScrollButton {
  @include list-scroll-button;
}

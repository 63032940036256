.GridRoot {
}

.GridHeader {

}

.GridBody {
  background-color: #ffffff;
}

.GridHeaderRow {

}

.GridHeaderCell {

}

.GridBodyRow {

}

.GridTimelineScrollbar {
  border-radius: 99px;
  background-color: #BEBEC8;
}

.GridBodyCell {

}

.header {
    border-bottom: 1px solid #E2E2E5;
}

.divider {
    width: 1px;
    margin: 12px 8px;
    height: 16px;
    background-color: #E2E2E5;
}

.content {
    padding: 10px 0;
}

.Cell {
    height: 30px;
    width: 100%;
}

.FirstRowCell {

}

.queueDot {
    &::before {
        content: '•';
        position: relative;
        vertical-align: baseline;
        vertical-align: -webkit-baseline-middle;
    }
}

.gridDot {
    &::before {
        @apply tw-text-gray-100;	
        content: '•';
        position: relative;
    }
}

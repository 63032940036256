



.TabsRoot {
  display: flex;
  flex-direction: column;
}

.TabsList {
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid var(--gray-5);
}

.TabsTrigger {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  outline: none;
  padding: 14px 20px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1;
  color: var(--gray-8);
  font-weight: 500;

  &[data-disabled] {
    cursor: not-allowed;
    color: var(--gray-6);
  }
}

.TabsTrigger[data-state='active'] {
  font-weight: bold;
  color: var(--gray-12);
}

.TabsTrigger:focus {
  position: relative;
}

.TabsContent {
  flex-grow: 1;
  height: 100%;
}

@import './Size.module.scss';

$ui-size-xsmall: theme('height.4');
$ui-size-small: theme('height.6');
$ui-size-medium: theme('height.8');
$ui-size-large: theme('height.10');
$ui-size-xlarge: theme('height.12');

$ui-padding-xsmall: theme('padding.2');
$ui-padding-small: theme('padding.2');
$ui-padding-medium: theme('padding.2');
$ui-padding-large: theme('padding.2');
$ui-padding-xlarge: theme('padding.2');

$ui-font-size-xsmall: theme('fontSize.xs');
$ui-font-size-small: theme('fontSize.xs');
$ui-font-size-medium: theme('fontSize.xs');
$ui-font-size-large: theme('fontSize.lg');
$ui-font-size-xlarge: theme('fontSize.xl');

$ui-icon-size-xsmall: theme('width.2');
$ui-icon-size-small: theme('width.2');
$ui-icon-size-medium: theme('width.2');
$ui-icon-size-large: theme('width.2');
$ui-icon-size-xlarge: theme('width.2');

@mixin queue-ui-fixed-height {
  height: $ui-size-medium;
  @include queue-ui-size-xsmall {
    height: $ui-size-xsmall;
  }
  @include queue-ui-size-small {
    height: $ui-size-small;
  }
  @include queue-ui-size-medium {
    height: $ui-size-medium;
  }
  @include queue-ui-size-large {
    height: $ui-size-large;
  }
  @include queue-ui-size-xlarge {
    height: $ui-size-xlarge;
  }
}

@mixin queue-ui-fixed-width {
  width: $ui-size-medium;
  @include queue-ui-size-xsmall {
    width: $ui-size-xsmall;
  }
  @include queue-ui-size-small {
    width: $ui-size-small;
  }
  @include queue-ui-size-medium {
    width: $ui-size-medium;
  }
  @include queue-ui-size-large {
    width: $ui-size-large;
  }
  @include queue-ui-size-xlarge {
    width: $ui-size-xlarge;
  }
}

@mixin queue-ui-x-padding {
  padding-left: $ui-padding-medium;
  padding-right: $ui-padding-medium;
  @include queue-ui-size-xsmall {
    padding-left: $ui-padding-xsmall;
    padding-right: $ui-padding-xsmall;
  }
  @include queue-ui-size-small {
    padding-left: $ui-padding-small;
    padding-right: $ui-padding-small;
  }
  @include queue-ui-size-medium {
    padding-left: $ui-padding-medium;
    padding-right: $ui-padding-medium;
  }
  @include queue-ui-size-large {
    padding-left: $ui-padding-large;
    padding-right: $ui-padding-large;
  }
  @include queue-ui-size-xlarge {
    padding-left: $ui-padding-xlarge;
    padding-right: $ui-padding-xlarge;
  }
}

@mixin queue-ui-fixed-font-size {
  font-size: $ui-font-size-medium;
  @include queue-ui-size-xsmall {
    font-size: $ui-font-size-xsmall;
  }
  @include queue-ui-size-small {
    font-size: $ui-font-size-small;
  }
  @include queue-ui-size-medium {
    font-size: $ui-font-size-medium;
  }
  @include queue-ui-size-large {
    font-size: $ui-font-size-large;
  }
  @include queue-ui-size-xlarge {
    font-size: $ui-font-size-xlarge;
  }
}
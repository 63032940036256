


.ScrollAreaRoot {
  @apply tw-w-full tw-h-full tw-bg-gray-400;
}

.Drawable {
  @apply tw-flex-1 tw-flex-col tw-table-cell tw-align-middle;
}

.Root {
  @apply tw-flex tw-flex-1 tw-flex-col tw-overflow-hidden tw-min-w-0;
}

.fullscreen {
  @apply tw-pointer-events-none;
  background: black;
}

.scaleFull {
  @apply tw-p-0;
}

.canvas {
  @apply tw-relative tw-box-border tw-overflow-hidden;
  &:not(.fullscreen) {
    @apply tw-overflow-visible;
    border: 1px solid var(--gray-5);
  }
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve-8);
}

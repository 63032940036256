.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  user-select: none;
  touch-action: none;
  cursor: pointer;

  &[data-orientation='horizontal'] {
    height: 20px;
  }

  &[data-orientation='vertical'] {
    flex-direction: column;
    width: 20px;
    height: 100%;
  }
}

.SliderTrack {
  background-color: #E4E4F0;
  border: 1px solid #D4D4E0;
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;

  &[data-orientation='horizontal'] {
    height: 4px;
  }

  &[data-orientation='vertical'] {
    width: 4px;
  }
}

.SliderRange {
  background-color: #D4D4E0;
  position: absolute;
  border-radius: 9999px;

  &[data-orientation='horizontal'] {
    height: 100%;
  }

  &[data-orientation='vertical'] {
    width: 100%;
  }
}

.SliderThumb {
  background-color: #fff;
  display: block;
  width: 12px;
  height: 12px;
  border: 1px solid #A2A3C3;
  border-radius: 99px;


  &:hover {
    background-color: #E4E4F0;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #00000030;
  }

  &:active {
    box-shadow: 0 0 0 4px #00000030;
  }
}

@import '../../components/dialog/Dialog.module.scss';
@import '../../components/buttons/button/Button.module.scss';

.AlertDialogOverlay {
  @include queue-dialog-overlay;
}

.AlertDialogContent {
  @include queue-dialog-content;
}

.AlertDialogTitle {
  @include queue-dialog-title;
}

.AlertDialogDescription {
  @include queue-dialog-description;
}

.AlertDialogFooter {
  @include queue-dialog-footer;
}

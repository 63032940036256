// default ui color
$ui-default-color: var(--gray-12);
$ui-default-color-hover: var(--gray-12);
$ui-default-color-active: var(--gray-12);
$ui-default-color-disabled: var(--gray-5);
$ui-default-background-color-disabled: var(--gray-5);
$ui-default-background-color: var(--white-a1);
$ui-default-background-color-hover: var(--gray-3);
$ui-default-background-color-active: var(--gray-5);
$ui-default-background-color-disabled: var(--gray-1);

// blue ui color
$ui-blue-color: var(--gray-1);
$ui-blue-color-hover: var(--gray-1);
$ui-blue-color-active: var(--gray-1);
$ui-blue-color-disabled: var(--gray-1);
$ui-blue-background-color: var(--blue-9);
$ui-blue-background-color-hover: var(--blue-10);
$ui-blue-background-color-active: var(--blue-11);
$ui-blue-background-color-disabled: var(--blue-11);

// red ui color
$ui-red-color: var(--gray-1);
$ui-red-color-hover: var(--gray-1);
$ui-red-color-active: var(--gray-1);
$ui-red-color-disabled: var(--gray-1);
$ui-red-background-color: var(--red-9);
$ui-red-background-color-hover: var(--red-10);
$ui-red-background-color-active: var(--red-11);
$ui-red-background-color-disabled: var(--red-11);

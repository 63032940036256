@mixin queue-ui-size-xsmall {
  &.xsmall {
    @content;
  }
}

@mixin queue-ui-size-small {
  &.small {
    @content;
  }
}

@mixin queue-ui-size-medium {
  &.medium {
    @content;
  }
}

@mixin queue-ui-size-large {
  &.large {
    @content;
  }
}

@mixin queue-ui-size-xlarge {
  &.xlarge {
    @content;
  }
}

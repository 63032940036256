.Root {
  @apply tw-p-2.5;
}

.ActualSizedContent {
  @apply tw-m-auto;
}

.ScaledContent {
  @apply tw-origin-top-left;
}

.Container {
  @apply tw-mt-5;
}

.RatioContainer {
  @apply tw-flex tw-items-center;
}

.TemplateContainer {
  @apply tw-mt-5;
}

.RatioSelector {
  @apply tw-flex-1 tw-ml-2.5 tw-justify-between;
}

.TemplateGroup {
  @apply tw-grid tw-grid-cols-4 tw-border tw-overflow-y-auto tw-h-60;
  border-color: var(--gray-5);
  grid-auto-rows: theme('height.20');
  .TemplateItem {
    @apply tw-h-full;
  }
}

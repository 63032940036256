

.SeparatorRoot {
  background-color: var(--gray-5);
}

.SeparatorRoot[data-orientation='horizontal'] {
  height: 1px;
  width: 100%;
}

.SeparatorRoot[data-orientation='vertical'] {
  height: 100%;
  width: 1px;
}

.Container {
  border-bottom: 1px solid var(--gray-5);
}

.ItemRoot {
  @apply tw-flex tw-justify-between tw-items-center tw-h-11 tw-py-3.5 tw-mx-2;
}

.ItemGroup {
  @apply tw-flex tw-h-full tw-items-center;
  .QueueIndicator {
    color: var(--gray-7);
  
    &.Current {
      color: var(--red-11);
    }
  }
  
  .HasEffect {
    color: var(--gray-11);
  }
}

.Separator {
  margin: 0 1rem;
}

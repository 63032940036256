


@import '../../components/buttons/button/Button.module.scss';
@import '../../styles/popover/Popover.module.scss';
@import '../../styles/list/List.module.scss';

.MenubarRoot {
  @apply tw-flex;
}

.MenubarTrigger {
  // base
  @include queue-button-base;

  // sizing
  @include queue-ui-x-padding;
  @include queue-ui-fixed-height;

  // font
  @include queue-ui-fixed-font-size;

  // theme
  @include queue-button-color-default;
  @include queue-button-color-blue;
  @include queue-button-color-red;
}

.MenubarContent,
.MenubarSubContent {
  @include popover;
  min-width: 220px;
}

.MenubarItem,
.MenubarSubTrigger,
.MenubarCheckboxItem,
.MenubarRadioItem {
  @include list-item;
}

.MenubarItem[data-state='open'],
.MenubarSubTrigger[data-state='open'] {
  @include list-item-hover;
}

.MenubarItem[data-highlighted],
.MenubarSubTrigger[data-highlighted],
.MenubarCheckboxItem[data-highlighted],
.MenubarRadioItem[data-highlighted] {
  @include list-item-hover;
}

.MenubarItem[data-disabled],
.MenubarSubTrigger[data-disabled],
.MenubarCheckboxItem[data-disabled],
.MenubarRadioItem[data-disabled] {
  @include list-item-disabled;
}

.MenubarSeparator {
  @include list-separator;
}

.MenubarItemIndicator {
  position: absolute;
  left: 0;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.RightSlot {
  @include list-item-right-slot;
}

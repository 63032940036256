


@mixin queue-dialog-overlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 50;
}

@mixin queue-dialog-content {
  @apply tw-p-5 tw-rounded-sm;
  background: var(--gray-1);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 50;
  &:focus {
    outline: none;
  }
}

@mixin queue-dialog-title {
  @apply tw-text-xl tw-font-medium tw-m-0;
}

@mixin queue-dialog-description {
  @apply tw-mt-5;
}

@mixin queue-dialog-footer {
  @apply tw-mt-5 tw-flex tw-justify-end tw-gap-2;
}

.DialogOverlay {
  @include queue-dialog-overlay;
}

.DialogContent {
  @include queue-dialog-content;
}

.DialogTitle {
  @include queue-dialog-title;
}

.DialogDescription {
  @include queue-dialog-description;
}

.DialogFooter {
  @include queue-dialog-footer;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

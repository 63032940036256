.Container {
  @apply tw-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  height: 48px;
}

.LogoContainer {
  @apply tw-flex tw-justify-center tw-items-center;
  font-family: IBM Plex Serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #533BB1;
}

.ContentContainer {
  @apply tw-flex tw-flex-col tw-flex-auto;
}

.title-container {
  font-size: 14px;
font-weight: 500;
line-height: 100%;
}

.slide-button {
  box-sizing: border-box;
  padding: 8px 12px !important;
  height: 30px !important;
  background-color: #E7E6EB !important;
  border-radius: 100px;

  &:hover {
    border-bottom: 0 !important;
  }
}
.canvas {
  // @apply py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-orange-500 hover:bg-orange-600;
  position: absolute;
  pointer-events: none;
  z-index: 100;

  & > rect {
    pointer-events: all;
  }
}

.topLeft,
.bottomRight {
  cursor: nwse-resize;
}

.topMiddle,
.bottomMiddle {
  cursor: ns-resize;
}

.topRight,
.bottomLeft {
  cursor: nesw-resize;
}

.middleLeft,
.middleRight {
  cursor: ew-resize;
}

.resizer {
  fill: var(--blue-9);
}

.rotation {
  cursor: crosshair;
  fill: var(--violet-9);
}

.RotationDegree {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
